import React, { Component } from "react";

import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import sha512 from 'js-sha512';

import Core from "../../Core.js";

//import 'antd/lib/button/style/index';

import styles from "./index.less";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loginOutput: null,
            error: false,
            tc_confirmed:false,
            pp_confirmed:false,
        };
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
    }

    componentWillMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onFinish(values) {
        const fail = () => {
            notification.error({
                message: 'Login',
                description: 'Os dados de acesso são inválidos.',
                top: 200
            });
            this.setState({ loading: false, error: true });
        };
        this.setState({ loading: true });
        Core.apiService(`login`, {
            data: {
                numero: parseInt(values.numero),
                palavra_passe: values.palavra_passe,
            },
            onSuccess: (data) => {
                if (data.json) {
                    Core.data.token = data.json.token;
                    const { onLoggedIn } = this.props;
                    if (onLoggedIn) {
                        onLoggedIn();
                    }
                    this.setState({ loading: false });
                } else {
                    console.log("Login data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Login data failed.", data);
                fail();
            }
        });
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    checkCheckBox = (rule, value, callback) => {
      if (!value) {
        callback('Please agree the terms and condtions!');
      } else {
        callback();
      }
    }

  render() {
        const { loading } = this.state;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 8 },
        };
        return (
            <div className="login">
              <Form
                {...layout}
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  label="Número de Associado"
                  name="numero"
                  rules={[{ required: true, message: 'Por favor insira o seu número de Associado.' }]}
                >
                  <Input placeholder="o seu número de Associado." />
                </Form.Item>

                <Form.Item
                  label="Palavra-passe"
                  name="palavra_passe"
                  rules={[{ required: true, message: 'Por favor insira a palavra-passe que recebeu por SMS.' }]}
                >
                  <Input.Password placeholder="a palavra-passe que recebeu por SMS." />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  name="tc-confirm"
                  className="tc-confirm"
                  valuePropName="checked"
                  rules={[
                    { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Por favor leia e aceite os Termos e Condições') },
                  ]}
                >
                  <Checkbox> Declaro que li e aceito os <a href="/docs/termos_e_condicoes.pdf" target="_blank">Termos e Condições</a></Checkbox>
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  name="pp-confirm"
                  className="pp-confirm"
                  valuePropName="checked"
                  rules={[
                    { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Por favor leia e aceite os Política de Privacidade') },
                  ]}
                >
                 <Checkbox> Declaro que li e aceito a <a href="/docs/politica_de_privacidade.pdf" target="_blank">Política de Privacidade</a></Checkbox>
                </Form.Item>
                <div className="pp-tc-read">
                </div>




                <Form.Item {...tailLayout} className="login__form-action">
                  <Button type="primary" htmlType="submit" loading={ loading }>
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            </div>
        );
    }
}
