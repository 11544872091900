import React, { Component } from "react";
import { Alert } from 'antd';
import Core from "../../Core.js";
import "./index.less";
import moment from 'moment';


export default class Closed extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        moment.locale('pt_pt');

    }

    componentDidMount() {

    }

    render() {
        moment.locale('pt_pt');

        const { associado } = Core.data;
        const endedDate = moment(associado.endDate).format('DD/MM/YYYY HH:mm');

        let text ='';
        if(associado.endDate !== undefined){
          text = `A eleição dos Órgãos Sociais do SIPLA (Mesa da Assembleia Geral, Direção e Conselho Fiscal) para o Triénio 2020/2023 foi encerrada: ${endedDate}.`
        } else {
          text = `A eleição dos Órgãos Sociais do SIPLA (Mesa da Assembleia Geral, Direção e Conselho Fiscal) para o Triénio 2020/2023 ainda não foi iniciada.`

        }
        return (
            <div className="closed">
              <Alert
                message="Urna Fechada"
                description={text}
                type="warning"
                showIcon
              />
            </div>
        );
    }
}
