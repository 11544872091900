import React, { Component } from 'react';
import {Layout, Typography} from 'antd';

import './CookiesPolicies.scss';

const { Title } = Typography;

export default class CookiesPolicies extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="cookies-policies">
              <div className="content-title">
                <Title>Política de Cookies</Title>
              </div>
              <div className="content-body">
                <div className="content-subtitle">
                  <Title level={3}>O que são Cookies</Title>
                </div>
                <p>Pequeno ficheiro de texto que o site coloca no dispositivo sem danificar o mesmo, por razões de segurança, fins estatísticos e para melhorar a experiência de navegação no site.  Estes retêm apenas informação relacionada com as suas preferências (eliminando a necessidade de introduzir repetidamente as mesmas informações), não inclui registo de quaisquer dados pessoais.</p>
                <p>A desativação dos Cookies pode resultar na impossibilidade em aceder a determinadas áreas do site ou receber informação personalizada.</p>
                <p className="cookies--policies__description">A informação gerada pela utilização dos cookies pode ser enviada para terceiros para análise de tendências, rastreio da navegação e desempenho dos mesmos, sem contudo identificar qualquer pessoa.</p>
                <div className="content-subtitle">
                  <Title level={3}>O que são Cookies</Title>
                </div>
                <p><b><u>Cookies Essenciais:</u></b> Permitem que navegue em veks.net acedendo a áreas seguras do site através de login. A ausência desses cookies inviabiliza a interatividade e a possibilidade de prestar os serviços a que se destina.</p>
                <p><b><u>Cookies Analíticos:</u></b> utilizamos estes cookies para efeitos estatísticos, analisando a forma como os utilizadores navegam no site e monitorizando a performance do mesmo. Estes visam detetar eventuais problemas e melhorar a experiência de utilização.</p>
                <p><b><u>Cookies de Funcionalidade:</u></b> utilizamos cookies de funcionalidade para nos permitir relembrar as preferências do utilizador de forma que não seja necessário voltar a configurar o site cada vez que o visita.</p>
                <p><b><u>Cookies permanentes:</u></b> ficam armazenados ao nível do browser nos seus equipamentos de acesso (PC, mobile e tablet) e são utilizados sempre que faz uma nova visita ao nosso site. São utilizados, geralmente, para direcionar a navegação aos interesses do utilizador, permitindo-nos prestar um serviço mais personalizado.</p>
                <p><b><u>Cookies de sessão:</u></b> são cookies temporários que permanecem no arquivo de cookies do seu browser até sair do website. A informação obtida por estes cookies serve para analisar padrões de tráfego na web, permitindo-nos identificar problemas e fornecer uma melhor experiencia de navegação.</p>
                <div className="content-subtitle">
                  <Title level={3}>Como gerir os seus cookies?</Title>
                </div>
                <p>Todos os navegadores permitem fazer a gestão dos cookies, para saber em detalhe como proceder, consulte as instruções para o seu navegador permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da seleção das definições apropriadas no respetivo navegador. Pode configurar os cookies no menu "opções" ou "preferências" do seu browser. </p>
                <p><i>Note-se, que ao desativar cookies, pode impedir que alguns serviços da web funcionem corretamente, afetando parcial ou totalmente, a navegação no website.</i></p>
              </div>
            </div>
        );
    }
}
