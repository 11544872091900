import React, { Component } from 'react';
import {Layout, Typography} from 'antd';

import './TermsCondition.scss';

const { Title } = Typography;

export default class TermsCondition extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="terms-condition">
              <div className="content-title">
                <Title>Termos e Condições</Title>
              </div>
              <div className="content-body">
                <p>O conteúdo integral deste site cuidados.pt é desenvolvido pela Sitana e é fornecido para a livre utilização, do outro lado, o utilizador que visita este site tem total liberdade para utilizar o conteúdo aqui disponível.</p>
                <p>A Sitana não se responsabiliza pela utilização destes conteúdos de forma ilegal.</p>
                <p>Considerando que a Sitana realiza venda de produtos e serviços tais como, Desenvolvimento Web e Mobile, e Soluções de Alojamento Web, e considerando o interesse do Cliente na compra dos produtos e/ou serviços oferecidos pela Sitana, o presente contrato tem por finalidade estabelecer as condições gerais de uso e compra de produtos e serviços por parte do cliente.</p>
                <ol>
                  <li>Confidencialidade: é de responsabilidade da Sitana a preservação da confidencialidade de todos os dados e informações fornecidos pelo Cliente no processo de compra.</li>
                  <li>Atendimento ao Cliente: O cliente dispõe desse serviço para sanar suas dúvidas, solucionar eventuais solicitações ou reclamações a respeito do seu pedido ou de qualquer conteúdo ou serviço disponibilizado.</li>
                  <li>Política de entrega: o prazo para entrega do produto e/ou serviço é informado durante o procedimento de compra, contabilizado em dias úteis. As entregas dos Produtos são realizadas de segunda a sexta-feira, das 9h às 19h. Excepcionalmente após acordo entre as partes, algumas entregas podem ocorrer em horários pós-laborais, sábados, domingos e feriados.</li>
                  <li>Alterações: após a finalização do pedido, é ainda possível alterar o pedido (serviço/produto), forma de pagamento, e/ou prioridades de entrega mediante contacto com o atendimento ao cliente.</li>
                  <li>Pagamento: Pagamento poderá ser efetuado por transferência bancária, Referência Multibanco, Paypal ou Boleto Bancário.</li>
                  <li>Incumprimento do pagamento poderá levar à suspensão da prestação de serviço ao fim de 30 dias (incluindo fins de semana) após entrega do serviço.</li>
                  <li>Direito de cancelamento: ao Cliente será facultado o exercício do direito de cancelamento caso se verifique e/ou comprove o incumprimento da Sitana na prestação do serviço ao cliente, hipótese na qual deverão ser observadas as seguintes condições: o prazo de desistência da compra do produto é de até 7 (sete) dias corridos a contar da data do pedido.</li>
                  <li>Reembolsos: O Cliente poderá solicitar reembolso através do Serviço de Atendimento ao Cliente, caso tenha havido cancelamento do serviço nos termos acima descritos. Poderá ainda haver um reembolso parcial de acordo com as especificidades do serviço prestado e acordo entre cliente e Sitana aquando do pedido.</li>
                </ol>
              </div>
            </div>
        );
    }
}
