import config from './config';

export default class Core {
    static menu = {
        click: () => {}
    };
    
    static data = {
        token: '',
        associado: null
    };

    static apiData(path, settings) {
        const success = settings.onSuccess ? settings.onSuccess : () => {};
        const fail = settings.onFail ? settings.onFail : () => {};
        fetch(config.api.data + path + "?time="+ new Date().getTime(), {
            method: settings.method ? settings.method : 'GET',
            credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                "Accept":  'application/json'
            }
        }).then(
            (response) => {
                if (response.ok) {
                    if (response.status == 204) {
                        return success();
                    } else {
                        const contentType = response.headers.get("Content-Type")
                        if (contentType && contentType.toLowerCase().indexOf("application/json") == 0) {
                            return response.json().then(function(data) {
                                success({ json: data })
                            });
                        } else {
                            return response.text().then(function(text) {
                                success({ text: text })
                            });
                        }
                    }
                } else {
                    return fail({ response: response });
                }
            }
        ).catch((e) => {
            fail({ error: e });
        });
    }

    static apiService(path, settings) {
        const success = settings.onSuccess ? settings.onSuccess : () => {};
        const fail = settings.onFail ? settings.onFail : () => {};
        const configs = {
            method: settings.method ? settings.method : 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                "Accept":  'application/json'
            }
        };
        if (settings.data) {
            configs.body = JSON.stringify(settings.data)
        }
        fetch(config.api.services + path, configs).then(
            (response) => {
                if (response.ok) {
                    if (response.status == 204) {
                        return success();
                    } else {
                        const contentType = response.headers.get("Content-Type")
                        if (contentType && contentType.toLowerCase().indexOf("application/json") == 0) {
                            return response.json().then(function(data) {
                                success({ json: data })
                            });
                        } else {
                            return response.text().then(function(text) {
                                success({ text: text })
                            });
                        }
                    }
                } else {
                    return fail({ response: response });
                }
            }
        ).catch((e) => {
            fail({ error: e });
        });
    }
};
