import React, { Component } from 'react';

import {Layout, Typography} from 'antd';
import Login from "../containers/Login/index.jsx";


import Core from '../Core';
import './Main.scss';
import VoteSteps from '../containers/VoteSteps/index.jsx';

const { Title } = Typography;

export default class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            current : props.current,
        };
        this.next = this.next.bind(this);
        this.handleLoggedIn = this.handleLoggedIn.bind(this);
    }

    componentDidMount() {

    }

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    handleLoggedIn() {
        this.setState({ logged: true });
    }

    render() {
        const { logged } = this.state;
        let main = null;
        main = <Login onLoggedIn={ this.handleLoggedIn }/>;
        if (logged) {
            main = <VoteSteps />;
        }

        return (
            <div className="main">
                <Layout>
                    <main>
                        { main }
                    </main>
                </Layout>
            </div>
        );
    }
}
