import React, { Component } from "react";

import { Descriptions, Button, Typography, Spin, Alert, notification } from 'antd';

import Core from "../../Core.js";

import "./index.less";

const { Title } = Typography;

export default class ConfDados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            data: null,
            wrong: false
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        const fail = () => {
            notification.error({
                message: 'Associado',
                description: 'Não foi possível carregar os dados.',
                top: 200
            });
            this.setState({ loading: false, error: true });
        };
        Core.apiService(`associado`, {
            data: {
                token: Core.data.token
            },
            onSuccess: (data) => {
                if (data.json) {
                    Core.data.associado = data.json;
                    this.setState({ loading: false, data: data.json });
                    if(Core.data.associado.onProgress == false){
                      const { onClosed } = this.props;
                      if (onClosed) {
                          onClosed();
                      }
                    }
                    if (Core.data.associado.votou == true) {
                        const { onDone } = this.props;
                        if (onDone) {
                            onDone();
                        }
                    }
                } else {
                    console.log("Associado data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Associado data failed.", data);
                fail();
            }
        });
    }

    render() {
        const { loading, error, data, wrong } = this.state;
        if (loading) {
            return (
                <div>
                  <Spin/>
                </div>
            );
        }
        if (error) {
            return (
                <div>
                  <Alert
                    message="Dados"
                    description="Não foi possível carregar os dados, tente mais tarde."
                    type="error"
                  />
                </div>
            );
        }
        return (
            <div className="conf-dados">
              <Title level={4}>Confirme os seus dados:</Title>
              <div className="conf-dados__table">
                <Descriptions bordered layout="horizontal">
                  <Descriptions.Item label="Número de Associado:" span={3}><strong>{ data.numero }</strong></Descriptions.Item>
                  <Descriptions.Item label="Nomes:"span={3}><strong>{ data.primeiro_nome }</strong></Descriptions.Item>
                  <Descriptions.Item label="Apelidos:"span={3}><strong>{ data.ultimo_nome }</strong></Descriptions.Item>
                  <Descriptions.Item label="Email:"span={3}><strong>{ data.mail }</strong></Descriptions.Item>
                </Descriptions>
              </div>
              {
                  wrong
                  ? <Alert
                      message="Correção dos Dados"
                      description={
                          <div>
                            <p>Por favor contacte a comissão eleitoral através do email: <a href="mailto: comissaoeleitoral@sipla.pt">comissaoeleitoral@sipla.pt</a></p>
                            <p>Com pedido de correção dos dados.</p>
                          </div>
                      }
                      type="warning"
                      showIcon
                    />
                  : <div className="conf-dados__action">
                      <p>Todos os dados estão corretos?</p>
                      <Button type="primary" onClick={() => this.props.onNext()}>Sim</Button>
                      <Button onClick={() => this.setState({ wrong: true }) }>Não</Button>
                    </div>
              }
            </div>
        );
    }
}
