import React, { Component } from "react";

import { message, Radio, Form, Button, Typography, Spin, Alert, notification, Popconfirm } from 'antd';

import Core from '../../Core.js';

import "./index.less";

const { Title } = Typography;

export default class FormVoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            list: [],
            submitting: false,
            optionSelected:false
        };
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.doubleConfirm = this.doubleConfirm.bind(this);
        this.doubleCancel = this.doubleCancel.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        const fail = () => {
            notification.error({
                message: 'Opções de Voto',
                description: 'Não foi possível carregar as opções de voto.',
                top: 200
            });
            this.setState({ loading: false, error: true });
        };
        Core.apiService(`voto-opcoes`, {
            data: {
                token: Core.data.token
            },
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({loading:false, list: data.json });
                } else {
                    console.log("Voto Opções data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Voto Opções data failed.", data);
                fail();
            }
        });
    }

    handleConfirm() {
        this.setState({ loading: false });
        message.success('Voto submetido com sucesso');
        console.log("success")
        this.props.next();
    }
    handleCancel() {
        this.setState({ loading: false });
    }

    onFinish(values) {
        const fail = () => {
            notification.error({
                message: 'Voto',
                description: 'Não pode ser submetido, tente mais tarde.',
            });
            this.setState({ submitting: false });
        };
        this.setState({ submitting: true });
        Core.apiService(`voto`, {
            data: {
                token: Core.data.token,
                voto_opcao_uid: values.voto_opcao_uid
            },
            onSuccess: (data) => {
                if (data.json.resultado == true) {
                    this.setState({ submitting: false });
                    this.props.onNext();
                } else {
                    console.log("Voto Opções data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Failed.", data);
                fail();
            }
        });
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    doubleConfirm(e) {
      console.log(e);
      e.submit();
      message.success('Click on Yes');
    }

    doubleCancel(e) {
      console.log(e);
      message.error('Click on No');
    }


    render() {
        const { loading, error, list, submitting } = this.state;
        var optionSelected = false;
        if (loading) {
            return (
                <div>
                  <Spin/>
                </div>
            );
        }
        if (error) {
            return (
                <div>
                  <Alert
                    message="Opções de Voto"
                    description="Não foi possível carregar as opções de voto, tente mais tarde."
                    type="error"
                  />
                </div>
            );
        }
        const radios = [];
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        for (const item of list) {
            radios.push(<Radio style={radioStyle} key={ item.uid } onChange={() => (this.setState({ optionSelected: true }))} value={ item.uid }>{ item.descricao }</Radio>);
        }
        return (
            <div>
                <p>Vem a Comissão Eleitoral iniciar o sufrágio das listas candidatas aos cargos competentes dos Órgãos Sociais do SIPLA, como referido em  <a href="/docs/convocatoria.pdf" target="_blank">Convocatória</a> para o efeito. Por favor, preencha o seu formulário de votação.</p>
              <p>Efectue o seu sentido de voto:</p>
                <Form
                    ref={ (ref) => { this.form = ref; }}
                    onLoad={() => (this.setState({ loaded: true }))}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}>
                    <Form.Item name="voto_opcao_uid" rules={[{ required: true, message: "Escolha uma opção para votar." }]}>
                        <Radio.Group>
                          { radios }
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item >
                      <Popconfirm
                        title="Tem a certeza que é este o seu sentido de voto?"
                        onConfirm={() => {this.form.submit()}}
                        onCancel={() => {console.log("click no!")}}
                        okText="SIM"
                        cancelText="NÃO"
                        style={!this.state.optionSelected? { display: 'none'} : {}}
                      >
                        <Button href="#" style={!this.state.optionSelected? { display: 'none'} : {}} loading={submitting}>Votar</Button>
                      </Popconfirm>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
