import React, { Component } from "react";
import {Layout, Menu} from 'antd';
import Burger from '@animated-burgers/burger-slip'; 
import '@animated-burgers/burger-slip/dist/styles.css'
import classNames from 'classnames';

import MainFooter from "./containers/Footer";

import Main from './pages/Main';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicies from './pages/PrivacyPolicies';
import CookiesPolicies from './pages/CookiesPolicies';
import Cookies from './containers/Cookies';

import Core from './Core';

import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const { Header, Content, Footer } = Layout;

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSelectedMenu: 'main',
            burgerMenu: false,
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if (window.location.pathname == '/') {
            this.setState({ defaultSelectedMenu: 'main' });
        } else {
            this.setState({ defaultSelectedMenu: 'none' });
        }
        Core.menu.click = this.onMenuClick;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        Core.menu.click = () => {};
    }

    updateWindowDimensions() {
        const { burgerMenu } = this.state;
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            burgerMenu: window.innerWidth > 800 ? false : burgerMenu
        });
    }

    onMenuClick(selectedMenu) {
        let { defaultSelectedMenu } = this.state;
        if (selectedMenu) {
            defaultSelectedMenu = selectedMenu;
        }
        this.setState({ burgerMenu: false, defaultSelectedMenu });
        window.scrollTo(0, 0);
    }

    render() {
        const { burgerMenu, defaultSelectedMenu } = this.state;
        return (
            <Router>
              <div className="page">
                <Layout>
                  <Header className={ classNames({ 'header-burger-open': burgerMenu }) }>
                    <h1 className="header-title">
                      <Link to="/" onClick={ () => this.onMenuClick('main') }>
                        <img className='logo' src="/images/logo.png" />
                      </Link>
                    </h1>{ /*
                    <div className="header-burger">
                      <Burger isOpen={ burgerMenu } onClick={ ()=> { this.setState({ burgerMenu: !burgerMenu }) } } />
                    </div>
                    <div className={ 
                        classNames({
                            'menu': true,
                            'menu-burger-open': burgerMenu
                        })
                    }>
                      <Menu
                        theme="dark"
                        mode={burgerMenu ? 'vertical' : 'horizontal'}
                        defaultSelectedKeys={ [ defaultSelectedMenu ] }
                        selectedKeys={ [ defaultSelectedMenu ] }>
                        <Menu.Item key="main">
                          <Link to="/" onClick={ () => this.onMenuClick('main') }>Principal</Link>
                        </Menu.Item>
                      </Menu>
                    </div>*/ }
                  </Header>
                  <Content>
                    <Switch>
                      <Route path="/termos-e-condicoes">
                        <TermsCondition/>
                      </Route>
                      <Route path="/politica-de-privacidade">
                        <PrivacyPolicies/>
                      </Route>
                      <Route path="/politica-de-cookies">
                        <CookiesPolicies/>
                        </Route>
                      <Route path="/">
                        <Main/>
                      </Route>
                    </Switch>
                  </Content>
                  <MainFooter/>
                </Layout>
              </div>
              <Cookies/> 
            </Router>
        );
    }
}
