import React, { Component } from 'react';

import { Steps, Button, message } from 'antd';
import ConfDados from '../ConfDados';
import FormVoto from '../FormVoto';
import Votou from '../Votou';
import Closed from '../Closed';

import "./index.less";

const { Step } = Steps;

class VoteSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleClosed = this.handleClosed.bind(this);
  }

  handleNext() {
      const current = this.state.current + 1;
      this.setState({ current });
  }

  handleDone() {
      this.setState({ current: 2 });
  }

  handleClosed() {
      this.setState({ current: 3 });
  }

  render() {
    const { current } = this.state;
    return (
      <div className="vote-steps">
        <Steps current={this.state.current}>
          <Step title={'Confirmação'} description={'Verifique os seus dados pessoais'}/>
          <Step title={'Votação'} description={'Realize o seu voto'}/>
          <Step title={'Confirmado'} description={'O seu voto foi submetido'}/>
        </Steps>
        <div className="vote-steps__content">
          {current === 0 &&(<ConfDados onNext={this.handleNext} onDone={ this.handleDone } onClosed={ this.handleClosed }/>)}
          {current === 1 &&(<FormVoto onNext={this.handleNext}/>)}
          {current === 2 &&(<Votou />)}
          {current === 3 &&(<Closed />)}
        </div>
      </div>
    );
  }
}

export default VoteSteps;
