import React, { Component } from "react";

import { Alert } from 'antd';

import Core from "../../Core.js";

import "./index.less";

export default class Votou extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        const { associado } = Core.data;
        return (
            <div className="votou">
              <Alert
                message="Votou"
                description={ `${associado.primeiro_nome} ${associado.ultimo_nome} o seu voto foi realizado com sucesso. Guarde ou imprima, o recibo de voto que foi enviado para o seu endereço de email.`}
                type="success"
                showIcon
              />
            </div>
        );
    }
}
