import React, { Component } from 'react';
import {Layout, Typography} from 'antd';

import './PrivacyPolicies.scss';

const { Title } = Typography;

export default class PrivacyPolicies extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="privacy-policies">
              <div className="content-title">
                <Title>Política de Privacidade</Title>
              </div>
              <div className="content-body">
                <div className="content-subtitle">
                  <Title level={3}>Política Geral de Segurança e Privacidade</Title>
                </div>
                <p>Esta política diz respeito às práticas de privacidade no âmbito da atividade realizada em cuidados.pt, à responsabilidade da Sitana Unipessoal Lda. Os dados pessoais que nos forneça durante a interação com os vários serviços serão tratados com as garantias de segurança e confidencialidade exigidas pelo enquadramento legal relativo à proteção de dados pessoais.</p>
                <p>Alguns conteúdos disponibilizados em cuidados.pt, contêm links para outros sítios sob a responsabilidade de entidades terceiras. Quando acede a sítios de outras entidades, ainda que a partir de cuidados.pt, deverá ler as respetivas políticas de privacidade.</p>
                  <p>O acesso e a utilização do cuidados.pt, devem ser livres por parte do utilizador presumindo que este leu, compreendeu e aceitou os Termos e Condições da sua utilização.</p>
                <div className="content-subtitle">
                  <Title level={3}>Privacidade</Title>
                </div>
                <p>O cuidados.pt, respeita o seu direito à privacidade. A interação com cuidados.pt não exige informação do utilizador, não sendo recolhidos dados pessoais sem o seu consentimento. Os dados recolhidos serão usados única e exclusivamente para fins estatísticos ou entrega do serviço subscrito.</p>
                <div className="content-subtitle">
                  <Title level={3}>Tipologia de Dados Pessoais</Title>
                </div>
                <p>Os dados pessoais sujeitos a recolha e tratamento são:</p>
                <ul>
                  <li><u>Newsletters:</u> para subscrever ao serviço de alertas e notificações por email, o utilizador(a) deve inserir o seu endereço de correio eletrónico:</li>
                  <li><u>interação com o simulador:</u> requer preenchimento de alguns dados pessoais (sexo, idade, localidade, comportamentos de risco,entre outros) que se mantêm em completo anonimato, sem quaisquer registo de identificação do utilizador(a).</li>
                </ul>
                <div className="content-subtitle">
                  <Title level={3}>Responsabilidade</Title>
                </div>
                <p>A Sitana Unipessoal Lda enquanto responsável pelo tratamento de dados:</p>
                <ul>
                  <li>Assegura que o tratamento dos seus dados pessoais é efetuado no âmbito das finalidades para as quais os mesmos foram recolhidos ou para finalidades compatíveis com aquelas;</li>
                  <li>Recolhe, utiliza e conserva apenas os dados pessoais necessários para a finalidade em causa;</li>
                  <li>Não procede a qualquer transmissão de dados pessoais para fins comerciais ou de publicidade.</li>
                </ul>
                <div className="content-subtitle">
                  <Title level={3}>Acesso e controlo dos seus Dados Pessoais</Title>
                </div>
                <p>Caso tenha subscrito ao serviço de Newsletters, possibilitamos, a seu pedido, o cancelamento do serviço com apagamento do seu endereço de email.</p>
                <div className="content-subtitle">
                  <Title level={3}>Encarregado de Proteção de Dados</Title>
                </div>
                <p>A Sitana, entidade responsável pela publicação do cuidados.pt, designou um Encarregado de Proteção de Dados que poderá ser contactado através do endereço eletrónico: admin@sitana.pt</p>
                <div className="content-subtitle">
                  <Title level={3}>Alteração à política de segurança e privacidade</Title>
                </div>
                <p>Os presentes Termos e Condições, que deverá ler atentamente poderão ser alterados considerando-se que as alterações entram em vigor a partir da data da sua publicitação neste sítio, fazendo-se expressa referência à data de atualização.</p>
              </div>
            </div>
        );
    }
}
