
import React, { Component } from 'react';
import { Layout } from 'antd';
import styles from './Footer.scss';
import Core from '../Core';
import {
  Link
} from "react-router-dom";


const { Footer } = Layout;

class MainFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.tableData) {
            return {
                tableData: nextProps.tableData
            }
        }
        return null;
    }

    render() {

        return (
            <Footer className="footer">
              {
                  <p>
                    <a href="/docs/termos_e_condicoes.pdf" target="_blank">Termos e Condições</a>
                    &nbsp; &middot; &nbsp;
                    <a href="/docs/politica_de_privacidade.pdf" target="_blank">Politica de Privacidade</a>
                  </p>
                  /*
                   <p>
                    <Link to="/termos-e-condicoes" onClick={ ()=> Core.menu.click('terms-condition') }>Termos e Condições</Link>
                    &nbsp; &middot; &nbsp;
                    <Link to="/politica-de-privacidade" onClick={ ()=> Core.menu.click('privacy-policies') }>Politica de Privacidade</Link>
                   </p>
                  */
              }
              <p>&copy; 2023 - <a href="https://www.sipla.pt/" target="_blank">SIPLA</a> - Sindicato Independente de Pilotos de Linhas Aéreas. Todos os direitos reservados.</p>
            </Footer>
        )
    }
}

export default MainFooter;
